<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="yellow" class="text-h4">mdi-account-school</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
      >
        {{ $t('student.student_title') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="darkpink" rounded dark @click="openDialog()">
        <v-icon left> mdi-plus-thick </v-icon>
        {{ $t('botoes.botao_novo') }}
      </v-btn>
      <v-dialog
        v-model="dialog"
        persistent
        :max-width="$vuetify.breakpoint.mdAndUp ? '40%' : '90%'"
      >
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('botoes.botao_novoaluno') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" sm="6" md="7">
                    <v-text-field
                      color="blue"
                      v-model="formStudent.name"
                      :rules="[rules.required]"
                      :label="$t('label.label_nome')"
                      @change="doUser(formStudent.name)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5">
                    <v-text-field
                      color="blue"
                      v-model="formStudent.user"
                      :rules="[rules.required, rules.user]"
                      :label="$t('label.label_usuario')"
                      readonly
                      :loading="userLoad"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-select
                      item-color="darkblue"
                      color="blue"
                      v-model="formStudent.schoolId"
                      :items="schools"
                      item-text="name"
                      item-value="id"
                      :label="$t('regisResponsavel.painel.escola')"
                      :no-data-text="$t('regisResponsavel.resgistrocadastro')"
                      :rules="[rules.option]"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      :label="$t('label.label_email.email')"
                      :rules="[rules.email]"
                      v-model="formStudent.email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" :sm="editMode ? 12 : 6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      offset-x
                      transition="slide-x-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          color="darkblue"
                          v-model="computedDateFormatted"
                          :label="$t('label.label_datanascimento')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @blur="
                            formStudent.birthday = parseDate(
                              computedDateFormatted
                            )
                          "
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-BR"
                        color="darkblue"
                        header-color="darkblue"
                        v-model="formStudent.birthday"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="!editMode">
                    <v-text-field
                      color="blue"
                      v-model="formStudent.password"
                      :rules="[rules.required, rules.length(4)]"
                      :label="$t('label.label_senha.label_senhaob')"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*{{ $t('regras.regra_obrigatorio') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn color="darkpink" rounded text @click="saveBtn()">
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="dialogDelete" max-width="550px">
      <v-card
        class="rounded-lg py-3 error--text"
        style="border-left: 12px inset red"
      >
        <v-card-title>
          <v-icon left large color="error">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_excluir.excluir1') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="error"
            dark
            rounded
            class="px-3"
            @click="deleteItemConfirm()"
          >
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resetDialog" max-width="550px">
      <v-card
        class="rounded-lg py-3 warning--text"
        style="border-left: 12px inset orange"
      >
        <v-card-title>
          <v-icon left large color="warning">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_dataAp') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="warning"
            dark
            rounded
            class="px-3"
            @click="resetPassword()"
          >
            {{ $t('admin.admin_restasenha') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="elevation-0 mt-1">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          color="blue"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('label.label_pesquisa')"
          single-line
          hide-details
          :style="
            $vuetify.breakpoint.smAndUp ? 'max-width: 30%' : 'max-width: 60%'
          "
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="Students"
        :search="search"
        :footer-props="{
          'items-per-page-options': [20, 30, 45, 60, -1],
          'items-per-page-all-text': $t('events.events_headers.headers1'),
          'items-per-page-text': $t('student.student_paginas'),
          'show-first-last-page': true,
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            small
            outlined
            rounded
            color="darkpink"
            class="px-2 mx-1"
            @click="resetItem(item)"
          >
            <v-icon small class="mr-1"> mdi-key-variant </v-icon>
            {{ $t('admin.admin_restasenha') }}
          </v-btn>
          <v-btn
            small
            outlined
            rounded
            color="darkblue"
            class="px-2 mx-1"
            @click="editItem(item)"
          >
            <v-icon small class="mr-1"> mdi-pencil </v-icon>
            {{ $t('botoes.botao_edita') }}
          </v-btn>
          <v-btn
            small
            outlined
            rounded
            color="error"
            class="px-2 mx-1"
            @click="deleteItem(item)"
          >
            <v-icon small class="mr-1"> mdi-delete </v-icon>
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon large color="success" class="mr-2">mdi-check-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon large color="error" class="mr-2">mdi-close-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_erro') }}!
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
export default {
  data() {
    return {
      dialog: null,
      formStudent: {},
      search: "",
      headers: [
        { text: this.$t('login.login_usuario.usuario1'), value: "name" },
        { text: this.$t('certificado.cert_headers.headers2'), value: "user" },
        { text: this.$t('regisResponsavel.painel.escola'), value: "school.nickname" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      Students: [],
      editedIndex: -1,
      password: "",
      dialogDelete: null,
      editMode: false,
      activePicker: null,
      menu: false,
      dateFormatted: null,
      rules: {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        none: true,
      },
      userExisting: false,
      successSnackbar: false,
      errorSnackbar: false,
      nameSchool: null,
      schoolName: [],
      showPassword: false,
      schools: [],
      userLoad: false,
      resetDialog: false,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.formStudent.birthday);
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    date() {
      this.dateFormatted = this.formatDate(this.formStudent.birthday);
    },
  },
  methods: {
    async doUser(name) {
      this.userLoad = true;

      name = await this.removeAccents(name);
      const array = await name.split(" ");
      let user = array[0] + "." + array[array.length - 1];

      let result = await this.verifyUser(user);

      let i = 1;
      let tryUser = user;
      while (result) {
        tryUser = user + `${i}`;
        result = await this.verifyUser(tryUser);
        i++;
      }
      setTimeout(() => {
        user = tryUser;
        this.formStudent.user = user.toLowerCase();
        this.userLoad = false;
      }, 1000);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    resetDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}${month}${year}`;
    },
    async resetPassword() {
      try {
        let password = await this.resetDate(this.password);

        const body = {
          password: password,
        };

        axios.put(`${url}/students/password/${this.editedIndex}`, body, {
          headers: {
            authorization: token,
          },
        });

        this.resetDialog = false;
        this.successSnackbar = true;
      } catch (error) {
        console.error(error);
        this.errorSnackbar = true;
      }
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    async verifyUser(res) {
      let response = await axios.get(`${url}/students/user?user=${res}`, {
        headers: {
          authorization: token,
        },
      });

      if (response.data != null) {
        return true;
      } else {
        return false;
      }
    },
    getStudent() {
      axios
        .get(`${url}/students`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.Students = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSchools() {
      axios
        .get(`${url}/schools`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.schools = res.data;
        });
    },
    async saveBtn() {
      let validation = this.$refs.form.validate();

      if (validation) {
        if (!this.editMode) {
          try {
            await this.registerStudent();
            this.editMode = false;
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        if (this.editMode) {
          try {
            await this.updateStudent(this.formStudent.id);
            this.editMode = false;
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        this.$refs.form.reset();
        setTimeout(() => {
          this.dialog = false;
        }, 200);
      }
    },
    openDialog() {
      this.dialog = true;
    },
    async registerStudent() {
      axios
        .post(`${url}/students`, this.formStudent, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.StudentCard();
          this.getStudent();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async StudentCard() {
      let user = this.formStudent.user;
      let schoolData = await this.getSchoolData(this.formStudent.schoolId);

      let body = {
        name: await this.shortenName(this.formStudent.name),
        user: user,
        school: schoolData.nickname,
        city: schoolData.city,
      };

      try {
        axios.post(`${url}/send-email/card`, body, {
          headers: {
            authorization: token,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getSchoolData(id) {
      const index = this.schools.findIndex((item) => item.id === id);
      return index !== -1 ? this.schools[index] : null;
    },
    async shortenName(fullName) {
      fullName = await this.removeAccents(fullName);
      let names = fullName.split(" ");

      if (names.length <= 2) {
        return fullName.toUpperCase();
      }

      let firstName = names[0];
      let lastName = names[names.length - 1];
      let middleInitial = names
        .slice(1, names.length - 1)
        .map((name) => name.charAt(0))
        .join(". ");

      return `${firstName} ${middleInitial}. ${lastName}`.toUpperCase();
    },
    removeAccents(str) {
      let normalizedStr = str.normalize("NFD");
      let removedAccentsStr = normalizedStr.replace(/[\u0300-\u036f]/g, "");

      return removedAccentsStr;
    },
    updateStudent(id) {
      axios
        .put(`${url}/students/${id}`, this.formStudent, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getStudent();
        })
        .catch((error) => {
          console.error(error);
          alert("Error");
        });
    },

    deleteItemConfirm() {
      axios
        .delete(`${url}/students/${this.editedIndex}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.dialogDelete = false;
          this.getStudent();
          setTimeout(() => {
            this.successSnackbar = true;
          }, 1000);
        })
        .catch((error) => {
          this.errorSnackbar = true;
          console.error(error);
        });
    },

    closeRegister() {
      this.dialog = false;
      this.editMode = false;
      this.$refs.form.reset();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formStudent = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.formStudent = Object.assign({}, item);
      this.dialogDelete = true;
    },
    resetItem(item) {
      this.editedIndex = item.id;
      this.password = item.birthday;
      this.formStudent = Object.assign({}, item);
      this.resetDialog = true;
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.formStudent = Object.assign({}, item);
      this.dialog = true;
      this.editMode = true;
    },
  },
  created() {
    this.getStudent();
    this.getSchools();
  },
};
</script>
