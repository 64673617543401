<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="green" class="text-h4">mdi-fast-forward</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        style="text-transform: uppercase"
        class="ml-3 font-weight-bold"
      >
        {{ $t('admin.admin_menu.menu36') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn dark color="darkpink" rounded @click="toExercises()">
        <v-icon left> mdi-plus-thick </v-icon>
        {{ $t('botoes.botao_novo') }}
      </v-btn>
    </v-toolbar>
    <div class="text-h6 ma-4">{{ $t('homework.homework_kitaula') }}</div>
    <div class="mt-2 mx-10">
      <v-expansion-panels focusable>
        <v-expansion-panel v-for="kt in kits" :key="kt.id">
          <v-expansion-panel-header ripple @click="getClassByKits(kt.id)">
            {{ kt.name }}

            <template v-slot:actions>
              <v-icon color="darkblue"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="headers"
              :items="classes"
              flat
              class="ma-2"
              hide-default-footer
              :no-data-text="$t('suporteProfessor.suporte_vazio')"
            >
              <template v-slot:[`item.details`]="{ item }">
                <v-btn
                  small
                  outlined
                  rounded
                  color="darkblue"
                  class="px-2 mx-1"
                  @click="seeSummaries(item.id)"
                >
                  <v-icon small class="mr-1"> mdi-image </v-icon>
                  {{ $t('botoes.botao_resumo') }}
                </v-btn>
                <v-btn
                  small
                  outlined
                  rounded
                  color="darkpink"
                  class="px-2 mx-1"
                  @click="seeExercises(item.id)"
                >
                  <v-icon small class="mr-1"> mdi-bookshelf </v-icon>
                  {{ $t('botoes.botao_exercicios') }}
                </v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-dialog
      v-model="dialogSummarie"
      transition="dialog-bottom-transition"
      width="55%"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text"
          >{{ $t('homework.homework_titleaula') }}</v-card-title
        >
        <v-card-text class="px-6 pt-6">
          <v-img
            v-if="summ"
            class="rounded-lg mx-auto"
            :src="summ"
            max-height="450px"
          ></v-img>
          <span v-if="!summ" class="text--primary" style="font-size: 1.1rem">
            {{ $t('homework.homework_adicionarARQ') }}
          </span>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            color="darkpink"
            class="px-3"
            text
            rounded
            :loading="isSelecting"
            @click="handleFileImport()"
          >
            <span v-if="summ">{{ $t('botoes.botao_edita') }}</span>
            <span v-if="!summ">{{ $t('botoes.botao_adicionar') }}</span>
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            @change="onFileChanged"
          />
          <v-btn
            color="darkpink"
            class="px-6 ml-3"
            dark
            rounded
            @click="closeSumm()"
          >
            {{ $t('botoes.botao_sair') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogExercises"
      transition="dialog-bottom-transition"
      width="55%"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          {{ $t('homework.homework_exercise.exercise') }}
          <v-spacer></v-spacer>
          <v-btn
            rounded
            dark
            color="darkpink"
            @click="toEdit(exerc)"
            v-if="!noExerc"
          >
            <v-icon small class="mr-1">mdi-pencil</v-icon>
            {{ $t('botoes.botao_edita') }}
          </v-btn>
        </v-card-title>
        <v-card-text class="px-8 pt-6">
          <div v-for="(dat, i) in exerc" :key="dat.id">
            <div class="text-subtitle-1 black--text font-weight-medium mb-2">
              {{ i + 1 }}. {{ dat.content }}
            </div>
            <div v-if="dat.image != null">
              <v-img :src="dat.image" max-width="150px" class="mx-auto mb-2" />
            </div>
            <div
              class="text-body-1"
              :class="opt.correct ? 'green--text' : ''"
              v-for="(opt, index) in dat.option"
              :key="opt.id"
            >
              <div v-if="dat.type == 'M'">
                {{ index + 1 }}- {{ opt.content }}
                <v-icon v-if="opt.correct" small color="green"
                  >mdi-check</v-icon
                >
              </div>
              <div v-if="dat.type == 'I'">
                {{ index + 1 }}.
                <v-img
                  :aspect-ratio="2 / 1"
                  :src="opt.content"
                  width="250px"
                  :class="opt.correct ? 'correctImg' : ''"
                  class="mb-2"
                />
              </div>
              <div v-if="dat.type == 'B'">
                <span v-if="opt.correct" class="font-weight-bold">
                  R: {{ opt.content }}</span
                >
              </div>
            </div>
            <v-divider class="my-6"></v-divider>
          </div>
          <div v-if="noExerc" class="text--primary" style="font-size: 1.1rem">
            {{ $t('homework.homework_exercise.exercise_no') }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  data() {
    return {
      dialogRegister: false,
      rules: {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        file: (v) => !!v || this.$t('regras.regra_file'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        none: true,
      },
      classes: [],
      description: null,
      noDataClass: this.$t('homework.homework_kit'),
      summary: null,
      classId: null,
      noExerc: false,
      kits: [],
      headers: [
        { text: this.$t('homework.homework_title'), value: "title" },
        { text: "", value: "details", sortable: false, align: "end" },
      ],
      dialogSummarie: false,
      dialogExercises: false,
      summ: null,
      isSelecting: false,
      selectedFile: null,
      exerc: null,
      allExerc: null,
    };
  },
  created() {

    this.getKits();
  },
  methods: {
    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      let selected = e.target.files[0];
      let formData = new FormData();
      formData.append("file", selected, selected.name);
      let res = await axios.post(`${url}/lessonsExtras/files`, formData);
      this.summ = res.data.url;
      let data = { classId: this.classId, uri: this.summ };
      axios.post(`${url}/summaries`, data, {
        headers: {
          authorization: token,
        },
      });
    },
    getClassByKits(kitId) {
      console.log(kitId)

    },
    getKits() {
      axios
        .get(`${url}/basics/kits`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.kits = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    toExercises() {
      this.$parent.$parent.$parent.exerciseId = null;
      this.$emit("message", "Exercícios");
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu37');
    },
    closeSumm() {
      this.dialogSummarie = false;
      setTimeout(() => {
        this.summ = null;
      }, 500);
    },
    async seeSummaries(id) {
      this.classId = id;
      let res = await axios.get(`${url}/summaries?lessons=${id}`, {
        headers: {
          authorization: token,
        },
      });

      this.dialogSummarie = true;
      this.summ = res.data[0].uri;
    },
    async seeExercises(id) {
      this.dialogExercises = true;

      let res = await axios.get(`${url}/exercisesByClass?lessons=${id}`, {
        headers: {
          authorization: token,
        },
      });
      this.exerc = res.data;

      if (this.exerc.length !== 0) {
        this.noExerc = false;
      } else {
        this.noExerc = true;
      }
    },
    toEdit(res) {
      let id = res[0].classId;
      this.$parent.$parent.$parent.exerciseId = id;
      this.$emit("message", "Exercícios");
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu37');
    },
  },
};
</script>

<style scoped>
.correctImg {
  border: 5px solid #468c00;
}
</style>
