<template>
  <div class="grey lighten-3" style="height: 100%">
    <v-toolbar tile class="elevation-2">
      <v-btn color="darkpink" rounded text @click="backHomework()">
        <v-icon small class="mr-1" v-if="$vuetify.breakpoint.smAndUp"
          >mdi-undo</v-icon
        >
        {{ $t('botoes.botao_voltar') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="darkpink"
        class="white--text ml-sm-3"
        rounded
        @click="saveBtn()"
        :loading="loading"
      >
        <v-icon small class="mr-1" v-if="$vuetify.breakpoint.smAndUp"
          >mdi-check
        </v-icon>
        {{ $t('botoes.botao_salva') }}
      </v-btn>
    </v-toolbar>
    <v-form ref="classForm">
      <v-row align="center" justify="center" class="mt-2">
        <v-col cols="11" md="7">
          <v-card elevation="3" class="rounded-lg">
            <v-card-title class="darkblue white--text">
             {{ $t('suporteProfessor.suporte_title') }}
            </v-card-title>

            <v-row class="px-2 px-md-6 pb-4">
              
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="kitId"
                  :items="kits"
                  item-text="name"
                  item-value="id"
                  :label="$t('label.label_kit')"
                  color="blue"
                  item-color="darkblue"
                  :placeholder="$t('admin.admin_digitakit')"
                  :no-data-text="$t('admin.admin_kitcadastrado')"
                  :rules="[rules.required, rules.option]"
                  :readonly="editMode"
                  @change="getClassByKits()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  color="blue"
                  v-model="classId"
                  :items="classes"
                  item-value="id"
                  item-text="title"
                  item-color="darkblue"
                  :label="$t('label.label_aulas')"
                  :placeholder="$t('admin.admin_digitapesquisa')"
                  :no-data-text="noDataClass"
                  :readonly="editMode"
                  :rules="[rules.required, rules.option]"
                  @change="getDescription(classId)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  readonly
                  no-resize
                  outlined
                  rows="4"
                  color="blue"
                  :label="$t('school.school_headers.heardes8')"
                  :hint="$t('admin.admin_campoautomatico')"
                  persistent-hint
                  v-model="textDescription"
                  @dblclick="snackbarDescription = true"
                >
                </v-textarea>

                <v-snackbar
                  v-model="snackbarDescription"
                  timeout="2000"
                  bottom
                  elevation="24"
                >
                  <span>
                    {{ $t('homework.homework_exercise.exercise_alterar.alterar1') }}
                    <strong>{{ $t('homework.homework_exercise.exercise_alterar.alterar2') }} </strong>
                  </span>

                  <template v-slot:action="{ attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      @click="snackbarDescription = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <!-- QUESTION -->
        <v-col cols="11" md="7" v-for="(exe, index) in exercises" :key="exe.id">
          <v-card elevation="3" class="rounded-lg my-1 px-2 px-md-6 pt-3">
            <v-row no-gutters>

              <!-- ENUNCIADO -->
              <v-col cols="12" sm="11" md="8">
                <v-textarea
                  color="blue"
                  :label="$t('school.school_headeratividade.headers1')"
                  rows="1"
                  auto-grow
                  :hint="$t('homework.homework_exercise.exercise_inserir')"
                  :rules="[rules.required]"
                  v-model="exe.content"
                ></v-textarea>
              </v-col>
              <!-- IMAGEM DO EXERCICIO -->
              <v-col cols="2" sm="1" class="text-center">
                <v-file-input
                  v-model="exe.file"
                  :rules="[rules.required]"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-image"
                  hide-input
                  full-width
                  class="mt-auto mx-auto pa-0 mr-n3 mr-sm-n5 mr-md-n3 mr-lg-n6"
                  @change="exercisesFile(index)"
                ></v-file-input>
              </v-col>
              <!-- TIPO DE EXERCICIO -->
              <v-col cols="10" sm="3">
                <v-select
                  color="blue"
                  v-model="exe.type"
                  :items="questionType"
                  :rules="[rules.required, rules.option]"
                  item-color="darkblue"
                  item-value="value"
                  item-text="text"
                  :label="$t('botoes.botao_seleciona')"
                ></v-select>
              </v-col>
              <!-- APRESENTACAO DA IMAGEM -->
              <v-col cols="12" style="margin-bottom: 1em;">
                <v-img
                  :src="exe.image"
                  :max-width="$vuetify.breakpoint.lgAndUp ? '25%' : '40%'"
                  :spect-ratio="1"
                  class="mx-auto"
                />
              </v-col>


              <!-- MULTIPLA ESCOLHA -->
              <v-col cols="12" md="7" v-if="exe.type == `P`">
                <v-checkbox 
                  color="blue"
                  item-color="darkblue"
                  v-model="exe.documento"
                  label="Documento">
                </v-checkbox>
                <v-file-input
                  v-if="exe.documento == true"
                  v-model="exe.url"
                  label="Arquivos"
                  prepend-icon=""
                  placeholder="Insira aqui seus arquivos"
                  prepend-inner-icon="mdi-paperclip"
                  multiple
                  color="darkblue"
                  :rules="[rules.file, rules.required]"
                ></v-file-input>
              </v-col>

              <!-- MULTIPLA ESCOLHA -->
              <v-col cols="12" md="7" v-if="exe.type == `A`">
                <v-checkbox 
                  color="blue"
                  item-color="darkblue"
                  v-model="exe.documento"
                  label="Documento">
                </v-checkbox>
                <v-file-input
                  v-if="exe.documento == true"
                  v-model="exe.url"
                  label="Arquivos"
                  prepend-icon=""
                  placeholder="Insira aqui seus arquivos"
                  prepend-inner-icon="mdi-paperclip"
                  multiple
                  color="darkblue"
                  :rules="[rules.required, rules.file]"
                ></v-file-input>
              </v-col>

              <!-- MULTIPLA ESCOLHA -->
              <v-col cols="12" md="7" v-if="exe.type == `H`">
                <v-checkbox 
                  color="blue"
                  item-color="darkblue"
                  v-model="exe.documento"
                  label="Documento">
                </v-checkbox>
                <v-file-input
                  v-if="exe.documento == true"
                  v-model="exe.url"
                  label="Arquivos"
                  prepend-icon=""
                  placeholder="Insira aqui seus arquivos"
                  prepend-inner-icon="mdi-paperclip"
                  multiple
                  color="darkblue"
                  :rules="[rules.required, rules.file]"
                ></v-file-input>
              </v-col>

              <!-- ACTIONS -->
              <v-col cols="12">
                <v-divider> </v-divider>
                <v-card-actions class="my-2">
                  <v-spacer></v-spacer>

                  <v-btn
                    color="darkpink"
                    rounded
                    :small="$vuetify.breakpoint.smAndUp ? true : false"
                    :text="$vuetify.breakpoint.smAndUp ? true : false"
                    :icon="!$vuetify.breakpoint.smAndUp"
                    @click="newExercises()"
                  >
                    <v-icon class="mr-sm-1"> mdi-plus-circle-outline </v-icon>
                    <span v-if="$vuetify.breakpoint.smAndUp">
                      {{ $t('homework.homework_newquest') }}
                    </span>
                  </v-btn>

                  <v-btn
                    color="red darken-1"
                    rounded
                    :small="$vuetify.breakpoint.smAndUp ? true : false"
                    :text="$vuetify.breakpoint.smAndUp ? true : false"
                    :icon="!$vuetify.breakpoint.smAndUp"
                    class="ml-sm-3"
                    @click="delExercises(exe)"
                    v-if="!(exercises.length <= 1)"
                  >
                    <v-icon class="mr-sm-1"> mdi-trash-can-outline </v-icon>
                    <span v-if="$vuetify.breakpoint.smAndUp"> {{ $t('botoes.botao_excluir') }} </span>
                  </v-btn>
                </v-card-actions>
              </v-col>

            </v-row>
          </v-card>
        </v-col>

      </v-row>
    </v-form>
    <v-dialog v-model="backDialog" max-width="500px">
      <v-card
        class="rounded-lg py-3 blue--text"
        style="border-left: 12px inset #197cff"
      >
        <v-card-title>
          <v-icon left large color="blue">mdi-alert</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_formulario') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn color="blue" dark rounded class="px-3" @click="backConfirm()">
            {{ $t('botoes.botao_continuar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="success"
        left
        >mdi-check-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <input ref="editing" class="d-none" type="file" @change="onFileChanged" />
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  data() {
    return {
      successSnackbar: false,
      backDialog: false,
      kitId: null,
      conteudo: {},
      kits: [],
      classId: null,
      classes: [],
      textDescription: null,
      snackbarDescription: false,
      noDataClass: this.$t('homework.homework_kit'),
      summary: null,
      rules: {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        none: true,
      },
      questionType: [
        { value: "P", text: this.$t('suporteProfessor.suporte_tipos.suporte1') },
        { value: "A", text: this.$t('suporteProfessor.suporte_tipos.suporte2') },
        { value: "H", text: this.$t('suporteProfessor.suporte_tipos.suporte3') },
      ],
      exercises: [
        {
          id: 1,
          content: null,
          documento: false,
          type: "P",
          url: null,
          delQuestion: false,
          addQuestion: true,
        },
      ],

      exerciseId: null,
      radioBinary: null,
      exercEdit: null,
      editMode: false,
      isSelecting: false,
      selectedFile: null,
      exercisesIndex: null,
      optionIndex: null,
      loading: false,
    };
  },
  created() {
    this.getKits();
    this.exercEdit = this.$parent.$parent.$parent.exerciseId;

    this.getEditExercise();
  },
  methods: {
    async getClassByKits() {
      await axios
        .get(`${url}/classes/kit/${this.kitId}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.classes = res.data;
          if (res.data == 0) {
            this.noDataClass = this.$t('homework.homework_nokit');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getKits() {
      axios
        .get(`${url}/basics/kits`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.kits = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDescription(aula) {
      const isClass = this.classes.find((cl) => {
        return cl.id === aula;
      });
      this.textDescription = isClass.description;
    },
    async getEditExercise() {
      if (this.exercEdit !== null) {
        this.editMode = true;

        let response = await axios.get(
          `${url}/classesByClassId?classId=${this.exercEdit}`,
          {
            headers: {
              authorization: token,
            },
          }
        );
        this.kitId = response.data[0].kitId;
        this.getClassByKits();
        this.classId = response.data[0].id;
        this.textDescription = response.data[0].description;

        // Puxar os exercícios
        let res = await axios.get(
          `${url}/exercisesByClass?lessons=${this.exercEdit}`,
          {
            headers: {
              authorization: token,
            },
          }
        );
        this.exercises = res.data;

        this.exercises.forEach((element, index) => {
          element.option.forEach((elem, i) => {
            if (elem.correct == true) {
              this.exercises[index].correct = i;
            }
          });
        });
      } else {
        this.editMode = false;
      }
    },
    backHomework() {
      this.backDialog = true;
    },
    backConfirm() {
      this.$emit("message", "Lições");
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu34');
    },
    async saveBtn() {
      console.log(this.exercises, this.classId)
      // let validation = this.$refs.classForm.validate();

      // if (validation) {
      //   if (!this.editMode) {
      //     if (this.summary) {
      //       await this.registerSummaries();
      //     }
      //     // await this.exercisesFile();
      //     await this.registerExercise();

      //     this.backConfirm();
      //   }
      //   if (this.editMode) {
      //     this.updateExercise();
      //   }
      // }
    },
    async updateExercise() {
      try {
        this.exercises.forEach(async (element, index) => {
          this.exercises[index].classId = this.exercEdit;

          // Mudar alternativa correta
          let corr = this.exercises[index].correct;
          for (let i = 0; i < element.option.length; i++) {
            element.option[i].correct = false;
          }
          this.exercises[index].option.correct = element.option;
          this.exercises[index].option[corr].correct = true;

          // Salvando Alterações do Exercício
          await axios.put(
            `${url}/exercises/${this.exercises[index].id}`,
            element,
            {
              headers: {
                authorization: token,
              },
            }
          );
          let option = this.exercises[index].option;
          await this.updateOptions(option);

          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.successSnackbar = true;
            setTimeout(() => {
              this.successSnackbar = false;

              this.backConfirm();
            }, 1500);
          }, 2000);
        });
      } catch (error) {
        console.error(error);
      }
    },
    async registerFile() {
      let formData = new FormData();
      formData.append("file", this.summary, this.summary.name);
      await axios
        .post(`${url}/summaries/files`, formData, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          let link = response.data.url;
          this.summary.uri = link;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async registerSummaries() {
      await this.registerFile();

      try {
        let data = {
          classId: this.classId,
          uri: this.summary.uri,
        };
        await axios.post(`${url}/summaries`, data, {
          headers: {
            authorization: token,
          },
        });
      } catch {
        this.errorSnackbar = true;
      }
    },
    addQuestions(exerc) {
      let position = this.exercises.indexOf(exerc);
      let data = {
        content: null,
        correct: false,
      };
      this.exercises[position].option.push(data);

      this.checkQuestions(position);
    },
    checkQuestions(pos) {
      if (this.exercises[pos].option.length >= 5) {
        this.exercises[pos].addQuestion = false;
      } else if (this.exercises[pos].option.length < 3) {
        this.exercises[pos].delQuestion = false;
      } else {
        this.exercises[pos].addQuestion = true;
        this.exercises[pos].delQuestion = true;
      }
    },
    delQuestions(obj, exerc) {
      let position = this.exercises.indexOf(exerc);
      const a = this.exercises[position].option.indexOf(obj);
      this.exercises[position].option.splice(a, 1);
      this.checkQuestions(position);
    },
    async registerExercise() {
      try {
        await this.exercises.forEach(async (element, index) => {
          this.exercises[index].classId = this.classId;
          let corr = this.exercises[index].correct;
          this.exercises[index].option[corr].correct = true;
          delete element.id;
          delete element.correct;
          let res = await axios.post(`${url}/exercises`, element, {
            headers: {
              authorization: token,
            },
          });
          let exeId = res.data.success.id;
          await this.registerOptions(exeId, index);
        });
      } catch (error) {
        console.error(error);
      }
    },
    async updateOptions(option) {
      for (const iterator of option) {
        await axios.put(`${url}/options/${iterator.id}`, iterator, {
          headers: {
            authorization: token,
          },
        });
      }
    },
    registerOptions(id, index) {
      if (id) {
        let data = this.exercises[index].option;
        data.forEach(async (element) => {
          if (this.exercises[index].type == "I") {
            let formData = new FormData();
            formData.append("file", element.content, element.content.name);
            let res = await axios.post(`${url}/lessonsExtras/files`, formData);
            element.content = res.data.url;
          }

          element.exercisesId = id;
          delete element.id;
          try {
            axios.post(`${url}/options`, element, {
              headers: {
                authorization: token,
              },
            });
          } catch (error) {
            console.error(error);
          }
        });
      }
    },
    async newExercises() {
      let data = {
        id: this.exercises[this.exercises.length - 1].id + 1,
        content: null,
        type: "P",
        delQuestion: false,
        addQuestion: true,
        documento: false,
        url: null,
      };
      await this.exercises.push(data);

      let altura = document.body.scrollHeight;
      window.scrollTo(0, altura);
    },
    delExercises(obj) {
      if (!(this.exercises.length <= 1)) {
        const ex = this.exercises.indexOf(obj);
        this.exercises.splice(ex, 1);
      }
    },
    async exercisesFile(position) {
      // this.exercises.forEach(async (element, i) => {
      let formData = new FormData();
      formData.append(
        "file",
        this.exercises[position].file,
        this.exercises[position].file.name
      );
      let res = await axios.post(`${url}/lessonsExtras/files`, formData);
      this.exercises[position].image = res.data.url;
      // });
    },
    handleFileImport(exerc, opt) {
      this.exercisesIndex = exerc;
      this.optionIndex = opt;

      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.editing.click();
    },
    async onFileChanged(e) {
      let selected = e.target.files[0];

      let formData = new FormData();
      formData.append("file", selected, selected.name);
      let res = await axios.post(`${url}/lessonsExtras/files`, formData);

      this.exercises[this.exercisesIndex].option[this.optionIndex].content =
        res.data.url;
    },
  },
};
</script>

<style scoped></style>
